<template>
   <div id="box">
      <h1>嗜睡量表问卷</h1>
      <p>
         您觉得自己在何种情况下容易嗜睡？对于下述8个问题，参考“睡眠状况”，选择最符合自己情况的选项。
      </p>
      <h4>1.开会或者坐着阅读时</h4>
      <el-radio-group v-model="radio">
         <el-radio label="0">A：不会打瞌睡</el-radio><br />
         <el-radio label="1">B：极小概率打瞌睡 </el-radio><br />
         <el-radio label="2">C：可能会打瞌睡</el-radio><br />
         <el-radio label="3">D: 极大概率打瞌睡</el-radio><br />
      </el-radio-group>
      <h4>2.坐着看电视时</h4>
      <el-radio-group v-model="radio1">
         <el-radio label="0">A：不会打瞌睡</el-radio><br />
         <el-radio label="1">B：极小概率打瞌睡 </el-radio><br />
         <el-radio label="2">C：可能会打瞌睡</el-radio><br />
         <el-radio label="3">D: 极大概率打瞌睡</el-radio><br />
      </el-radio-group>
      <br />
      <h4>3.安静地坐在会议室、电影院、剧院等时</h4>
      <el-radio-group v-model="radio2">
         <el-radio label="0">A：不会打瞌睡</el-radio><br />
         <el-radio label="1">B：极小概率打瞌睡 </el-radio><br />
         <el-radio label="2">C：可能会打瞌睡</el-radio><br />
         <el-radio label="3">D: 极大概率打瞌睡</el-radio><br />
      </el-radio-group>
      <br />
      <h4>4.长时间坐车时中间不休息（超过1小时）</h4>
      <el-radio-group v-model="radio3">
         <el-radio label="0">A：不会打瞌睡</el-radio><br />
         <el-radio label="1">B：极小概率打瞌睡 </el-radio><br />
         <el-radio label="2">C：可能会打瞌睡</el-radio><br />
         <el-radio label="3">D: 极大概率打瞌睡</el-radio><br />
      </el-radio-group>
      <h4>5.下午躺着休息时</h4>
      <el-radio-group v-model="radio4">
         <el-radio label="0">A：不会打瞌睡</el-radio><br />
         <el-radio label="1">B：极小概率打瞌睡 </el-radio><br />
         <el-radio label="2">C：可能会打瞌睡</el-radio><br />
         <el-radio label="3">D: 极大概率打瞌睡</el-radio><br />
      </el-radio-group>
      <h4>6.坐着与人说话交谈时</h4>
      <el-radio-group v-model="radio5">
         <el-radio label="0">A：不会打瞌睡</el-radio><br />
         <el-radio label="1">B：极小概率打瞌睡 </el-radio><br />
         <el-radio label="2">C：可能会打瞌睡</el-radio><br />
         <el-radio label="3">D: 极大概率打瞌睡</el-radio><br />
      </el-radio-group>
      <h4>7.饭后（未饮酒）、静静地坐着时</h4>
      <el-radio-group v-model="radio6">
         <el-radio label="0">A：不会打瞌睡</el-radio><br />
         <el-radio label="1">B：极小概率打瞌睡 </el-radio><br />
         <el-radio label="2">C：可能会打瞌睡</el-radio><br />
         <el-radio label="3">D: 极大概率打瞌睡</el-radio><br />
      </el-radio-group>
      <h4>8.在汽车中，因为交通原因而停下的几分钟内</h4>
      <el-radio-group v-model="radio7">
         <el-radio label="0">A：不会打瞌睡</el-radio><br />
         <el-radio label="1">B：极小概率打瞌睡 </el-radio><br />
         <el-radio label="2">C：可能会打瞌睡</el-radio><br />
         <el-radio label="3">D: 极大概率打瞌睡</el-radio><br />
      </el-radio-group><br />

      <el-button type="success"
                 @click="ExamSolutionADD"
                 :disabled="isdisabled">提交</el-button>
   </div>
</template>

<script>
import { Message } from "element-ui";
import axios from "../../http/axios";
export default {
   mounted () {
      this.get();
   },
   updated () {
      // console.log("总分：", this.total);
      // console.log("说明", this.explain);
   },
   methods: {
      async ExamSolutionADD () {
         const data = await axios({
            url: `/ExamSolution/Add?ETID=${this.ETID}&ETName=嗜睡量表ESS&CID=${this.cid}&CName=${this.name}&Data=暂无&Score=${this.total}&Solution=${this.explain}&Review=暂无建议&Section=/&Doctor=/`,
            method: "post",
            data,
         });
         if (data.data.data == true) {
            this.isShow = !this.isShow;
            Message.success("提交成功");
            this.$router.push("/Assessment");
         }
      },
      //  获取用户的token 中保存的username 并渲染到头部区域 用户：的位置
      get () {
         const patientN = localStorage.getItem("patientN");
         const patientI = localStorage.getItem("patientI");
         this.name = JSON.parse(patientN);
         this.cid = JSON.parse(patientI);
      },
      //  // 点击提交上传评分，并且删除本地存储的患者信息
      // test() {
      //   console.log(11111111, this.name);
      //   localStorage.removeItem("patientN");
      //   localStorage.removeItem("patientI");
      // },
   },
   computed: {
      isdisabled () {
         if (
            this.radio == "" ||
            this.radio1 == "" ||
            this.radio2 == "" ||
            this.radio3 == "" ||
            this.radio4 == "" ||
            this.radio5 == "" ||
            this.radio6 == "" ||
            this.radio7 == ""
         ) {
            return true;
         }
         if (
            this.radio != "" ||
            this.radio1 != "" ||
            this.radio2 != "" ||
            this.radio3 != "" ||
            this.radio4 != "" ||
            this.radio5 != "" ||
            this.radio6 != "" ||
            this.radio7 != ""
         ) {
            return false;
         }
      },
      explain () {
         if (this.total < 6) {
            return "正常";
         }
         if (this.total >= 6 && this.total <= 9) {
            return "轻度嗜睡";
         }
         if (this.total >= 10 && this.total <= 16) {
            return "中度嗜睡";
         }
         if (this.total > 16) {
            return "重度嗜睡";
         }
      },
      total () {
         return (
            this.radio * 1 +
            this.radio1 * 1 +
            this.radio2 * 1 +
            this.radio3 * 1 +
            this.radio4 * 1 +
            this.radio5 * 1 +
            this.radio6 * 1 +
            this.radio7 * 1
         );
      },
      // a1() {
      //   return this.radio * 1;
      // },
      // a2() {
      //   return this.radio3 * 1;
      // },
      // a3() {
      //   return this.radio8 * 1;
      // },
   },
   data () {
      return {
         ETID: "E8",
         isShow: false,
         name: "",
         cid: "",
         radio: "",
         radio1: "",
         radio2: "",
         radio3: "",
         radio4: "",
         radio5: "",
         radio6: "",
         radio7: "",
      };
   },
};
</script>

<style lang="scss" scoped>
// @import "./style.scss";
.el-radio {
   margin: 10px;
}
body {
   background: rgb(220, 245, 179);
}
#box {
   width: 100%;
   margin: auto;
   text-align: left;
   margin-left: 30px;
   // background: rgb(206, 245, 175);
}
.label_color {
   color: rgb(202, 44, 207);
}
h1 {
   text-align: center;
}

h4 {
   color: rgb(140, 102, 245);
}
</style>
